import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import { Box, Stack, Heading } from "@chakra-ui/react"
import { convert } from 'html-to-text';
import styled from "@emotion/styled"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"

import Container from "../components/Container"
import Seo from "../components/seo"
import { responsive } from "../components/ThemeProvider/theme"

const HTMLWrapper = styled(Box)`
img {
  display: inline-block;
}
`

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const featuredImage = {
    image: getImage(post.featuredImage?.node?.localFile),
    alt: post.featuredImage?.node?.alt || ``,
  }
  console.log(post.content)
  return (
    <Container py={responsive('1em', '4em')} my={responsive('2em', '4em')} px={responsive('2em', '6em')}>
      <Seo title={post.title} description={convert(post.excerpt,  { limits: { maxChildNodes: 1, ellipsis: '' } })} />

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <Stack as="header">
          {featuredImage?.image && (
            <GatsbyImage
              {...featuredImage}
              style={{ marginBottom: 50 }}
            />
          )}
          <Heading color="gray.700" as="h1" itemProp="headline">{parse(post.title)}</Heading>

          <p>By {post.author?.node?.name} | {post.date}</p>

          {/* if we have a featured image for this post let's display it */}

        </Stack>

        {!!post.content && (
          <HTMLWrapper
            mt="2em"
            as="section"
            className="entry-content"
            itemProp="articleBody"
            color="gray.700"
          >{parse(post.content)}</HTMLWrapper>
        )}

      </article>

      <Box className="blog-post-nav" mt="2em">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
            margin: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.uri} rel="prev">
                ← {parse(previous.title)}
              </Link>
            )}
          </li>

          <li>
            {next && (
              <Link to={next.uri} rel="next">
                {parse(next.title)} →
              </Link>
            )}
          </li>
        </ul>
      </Box>
    </Container>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "YYYY-MM-DD")
      author {
        node {
          name
        }
      }

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
